<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="trackingNumber"
      name="trackingNumber"
      label="运单号"
      placeholder="请输入"
    />
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit">
          确认
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      orderId: useRoute.query.orderId,
      trackingNumber: ''
    })
    const onSubmit = (values) => {
      post('/order.deliver', {
        orderId: state.orderId,
        ...values
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          useRouter.go(-1)
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit
    }
  }
}
</script>

<style scoped>
</style>
